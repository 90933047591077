<template>
    <v-expansion-panels class="mt-4" v-model="panel">
        <hb-expansion-panel class="mb-2">
            <template v-slot:title>
                Single Signon Provider
            </template>
            <template v-slot:content>
                <hb-form label="Provider" required>
                    <HbTextField v-model="form.name"></HbTextField>
                </hb-form>
                <hb-form label="Client Id" required>
                    <HbTextField v-model="form.value.clientId"></HbTextField>
                </hb-form>
                <hb-form label="Client Secret" required>
                    <HbTextField v-model="form.value.clientSecret"></HbTextField>
                </hb-form>
                <hb-form label="Tenant Id" required>
                    <HbTextField v-model="form.value.clientSecret"></HbTextField>
                </hb-form>
                <hb-form label="Redirect URI" required>
                    <HbTextField v-model="form.value.redirectUri"></HbTextField>
                </hb-form>
             </template>
             <template v-slot:footer>
            <hb-bottom-action-bar :top-border="false"><template v-slot:right-actions><hb-btn @click="save">Save</hb-btn></template></hb-bottom-action-bar>
        </template>
        </hb-expansion-panel>
    </v-expansion-panels>
</template>
<script type="text/babel">
    import { mapGetters } from 'vuex';
    export default {
        name: 'SSOConfigurationCard',
        data() {
            return {
                panel: 0,   
                form: {
                    id: '',
                    name: '',
                    value: {
                        clientId: "",
                        clientSecret: "",
                        tenantId: "",
                        redirectUri: ""
                    }
                } 
            }
        },
        props: ['connection', 'provider', 'ssoproviders'], 
        mounted() {
            if(this.provider?.value){
                this.form = JSON.parse(JSON.stringify(this.provider))
            }
        },
        computed:{
             ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }), 
        },
        components: {
            //Authorizenet,
            //FatzebraCard,
            //TsysCard,
            //TenantPaymentsCard
        },
        methods:{
            save(selected){
                let form = Object.assign(this.form, selected);
                this.$emit('save', form);
            }, 
            remove(selected){
                this.$emit('remove', selected);
            },
            cancel(){
                this.form = JSON.parse(JSON.stringify(this.connection));
            },
        },
        watch: {
            connection: { 
                handler: function (val, oldVal) {
                     this.form = JSON.parse(JSON.stringify(val));
            
                },
                deep: true

            }

        }
    }

</script>
