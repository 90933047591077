<template>
  <div class="section-content pt-0 pr-0">
      <hb-notification not-dismissable v-if="!hasPermission('manage_payment_gateways')" type="caution">You do not have permission to manage payment connections.</hb-notification>
      <div class="mr-6 mt-n5">
        <Provider :key="count" :provider="provider" :ssoproviders="merchants.ssoConfig"></Provider>
      </div>
  </div>
</template>

<script type="text/babel">
import api from '../../../../assets/api.js'
import { mapGetters, mapActions } from "vuex";
import Provider from './SSOManager.vue';
import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
export default {
  name: 'SSOConfiguration',
  mixins: [notificationMixin],
  data() {
    return {
      property_id: '',
      merchants: {
        ssoConfig: [
          { label: 'Azure AD', value: 'azure_sso'},
          { label: 'Okta SSO', value: 'okta_sso'},
          { label: 'Google', value: 'google_sso'}
        ],
      },
      selected: {},
      provider:{
        ssoConfig: {
          id: '',
          name: '',
          value: {}
        }
      },
      count: 0,
    }
  },
   computed: {
    ...mapGetters({
        onboardingData: 'onboardingTabsStore/getOnboardingData',
        hasPermission: 'authenticationStore/rolePermission'
      }),
      providerName(){
        let merchant = this.providers[this.selected.type].find(m => m.value === this.selected.name);
        
        return merchant.label;
      }, 
    },
  
  components: {
    Provider
  },
  created() {
    this.fetchProviders()
  },
  methods: {
    async fetchProviders() {
      let response = await api.get(this, api.GET_PROVIDERS); 
      this.provider = response.provider;
      this.count++
    },

    async save () {
    },
    deleteConnection () {
    },
    async confirmDelete () { 
    }

  }
}
</script>

<style scoped>
.payment-label {
  background-color: #F9FAFB;
  height: 56px;
  color: #101318;
  display:flex;
  padding-left: 8px;
}
.exp-panel {
  background-color:  #F9FAFB;
}
</style>
